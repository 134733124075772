.header {
  bottom: 14.85px;
  height: 73.15px;
  background: #ffffff;
}

.logo {
  cursor: pointer;
  margin-left: 30.64px;
  height: 35px;
  width: 142px;
}

.search {
  position: relative;
  margin-left: 66px;
}

.search .search_icon {
  position: relative;
  left: 166px;
  top: 6px;
}

/* .search:hover .search_icon {
  visibility: hidden;
} */

.search {
  display: flex;
  height: 40px;
  background: #f6f7fa;
  border: 1px solid #e7e7e7;
  border-radius: 100px;
  margin-right: 23px;
}

.search input {
  border: none;
  background: #f6f7fa;
  border-radius: 100px;
}

textarea:focus,
input:focus {
  outline: none;
}
.counter {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #ff6760;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  top: -5px;
  margin-left: 1152px;
  margin-top: 25px;
}

.avatarContainer {
  background: #f6f8fb;
  border: 1px solid #e9eff8;
  border-radius: 7px;
  justify-content: center;
  padding: 10px;
  gap: 5px;
  cursor: pointer;
}

.avatar {
  height: 39.18px;
  width: 39.18px;
  margin-right: 9px;
  flex: 20%;
}
.nameContainer {
  flex: 60%;
}
.headername {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #192a3e;
}

.headersubname {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}

.headerContainer {
  padding: 15px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
}

.navIcon {
  flex: 60%;
  align-items: center;
  justify-content: space-between;
}
.headerRightContainer {
  flex: 40%;
  gap: 20px;
}
.settingBox {
  margin-top: 80px;
  margin-right: 350px;
  position: absolute;
}
.notificationBox {
  margin-top: 80px;
  margin-right: 250px;
  position: absolute;
}

.HamburgerIcon {
  cursor: pointer;
  font-size: 30px;
}
