.buttonComponent {
  height: 15%;
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

.button {
  width: 100%;
  inset: none;
  border: none;
  border-radius: 8px;
  min-width: 65px;
  height: 38px;
  padding: 7px 16px;
}

.filled {
  background-color: #558eff;
  color: white;
}

.filled:hover {
  background-color: #467eeb;
}

.filled:disabled {
  background-color: rgba(0, 72, 217, 0.45);
}

.outline {
  background-color: white;
  color: #558eff;
  border: 1px solid #558eff;
}

.outline:hover {
  background-color: #cfe7ff;
}

.outline:disabled {
  background-color: #ffffff;
  color: #9fbcf6;
  border: 1px solid #a7bde9;
}

.success {
  background-color: #13ac5a;
  color: white;
}

.success:hover {
  background-color: #0b8544;
}

.success:disabled {
  background-color: #91dfb4;
}

.danger {
  background-color: #e84242;
  color: white;
}

.danger:hover {
  background-color: #c81111;
}

.danger:disabled {
  background-color: #d17070;
}
