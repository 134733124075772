.headerIcon {
  min-width: 10px;
  min-height: 10px;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.tooltiptext {
  margin-top: 10px;
  margin-left: -20px;
}

.headerIcon:hover {
  min-width: 10px;
  min-height: 10px;
  padding: 12px;
  border-radius: 50%;
  background-color: #ebebeb;
}

.headerIcon .tooltiptext {
  visibility: hidden;
  background-color: #5f6368;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px 8px;
  position: absolute;
  margin-top: 45px;
  font-size: 11px;
  font-weight: 600;
}
.headerIcon:hover .tooltiptext {
  visibility: visible;
}
.headerIcon:focus .tooltiptext {
  visibility: hidden;
}
