.outer_loader_box1 {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: 10px solid white;
  border-top-color: #558eff;
  border-bottom-color: #558eff;
  border-left-color: #558eff;
  animation: example 2s linear infinite;
}
.outer_loader_box2 {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: 10px solid #e5e9f2;
  border-top-color: #558eff;
  border-bottom-color: #558eff;
  animation: example 2s linear infinite;
}
.outer_loader_box3 {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: 10px solid #e5e9f2;
  animation: example 2s linear infinite;
}
@keyframes example {
  to {
    transform: rotate(1turn);
  }
}
