.headerContainer {
  padding: 10px 13px 10px 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  position: sticky;
  top: 0;
  z-index: 99999;
}
.mainContainer {
  min-height: 100px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 3%;
  margin-top: -10px;
}
.title {
  font-weight: 500;
  border-bottom: 1px solid black;
  text-align: center;
}
.item {
  display: flex;
  justify-content: center;
}
.mainUserContainer {
  height: 150;
  width: 150px;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  right: -130px;
  top: 2px;
  background: #ffffff;
}
.avatarContainer {
  background: #f6f8fb;
  border: 1px solid #e9eff8;
  border-radius: 7px;
  justify-content: center;
  top: 20px;
}

.avatar {
  height: 39.18px;
  width: 39.18px;
  margin: 10px;
  flex: 33%;
}
.nameContainer {
  flex: 33%;
}
.headername {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #192a3e;
}

.headersubname {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}
.logout {
  flex: 33%;
  padding: 10px;
  margin-left: 60px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #192a3e;
}

.logout button {
  border: none;
  outline: none;
  background: #ffff;
  gap: 5px;
}
