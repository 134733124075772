.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.checkbox:checked {
  background: #558eff;
}
