.aSideIcon {
  width: 64.43px;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  font-style: normal;
  font-size: 25px;
  line-height: 48px;
  box-shadow: 3px 0 5px -2px rgba(0, 0, 0, 0.15);
}

.SidebarLabel {
  color: #52575c;
}

.SidebarLabel:hover {
  color: #336cfb;
}

.activeRoute .SidebarLabel {
  color: #336cfb;
}

.copyright {
  font-size: small;
  display: flex;
  /* margin-top: 180px; */
  justify-content: flex-end;
}

.MainSidebar {
  display: flex;
}

.user {
  margin-top: 20px;
}

.avatar {
  margin-top: 20px;
  height: 39px;
  width: 39px;
  padding-left: 5px;
}

.svgIcon {
  fill: #adadad;
  transition: fill 350ms;
}

.sidebarItem:hover .svgIcon {
  fill: #336cfb;
  transition: fill 350ms;
}

.activeRoute .svgIcon {
  fill: #336cfb;
  transition: fill 350ms;
}

.copyRight {
  position: absolute;
  bottom: 24px;
  line-height: 1.79em;
  font-size: 14px;
  color: rgb(32, 83, 127);
}
