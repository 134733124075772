.rowsSelectedtext {
  margin: 0 28px 0 6px;
}

.actionButton {
  background: #ffffff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 4px 4px 4px 6px;
  cursor: pointer;
}

.filterButton {
  background: #ffffff;
  border: 0.5px solid #858c94;
  border-radius: 5px;
  margin-right: 16px;
}

.filterText {
  color: #6c6c6c;
  font-size: 14px;
}
