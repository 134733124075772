.loginPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #558eff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm {
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 14px 54px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 30px;
}

.loginHead {
  font-weight: 600;
  font-size: 22px;
  color: #25282b;
  margin-bottom: 3px;
}

.loginDesc {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #959595;
}

.middleFieldLogin {
  display: flex;
  flex: 60%;
}

.middleFieldLoginBox {
  display: flex;
  margin-top: 25px;
}

.loginFooter a {
  text-decoration: none;
}

.left {
  flex: 60%;
}

.right {
  flex: 40%;
}

.loginCheckBoxText {
  font-weight: 400;
  font-size: 14px;
  margin-top: -3px;
  letter-spacing: 0.1px;
  color: #52575c;
  margin-left: 10px;
}

.middleFieldLoginBox a {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  float: right;
  letter-spacing: 0.1px;
  margin-top: -3px;
}

.loginButton a {
  text-decoration: none;
  color: white;
}

.loginButton a:hover {
  color: rgb(255, 255, 255);
}

.loginButton button {
  width: 100%;
  height: 43px;
  border-radius: 6px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}

.loginFooter {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.33px;
  color: #52575c;
  margin-top: 10px;
}

.inputField {
  height: 60px;
  margin-top: 25px;
}

.inputPasswordField {
  margin-top: 25px;
}

.inputPasswordField Input[type='password'] {
  height: 50px;
}

.inputPasswordField Input[type='text'] {
  height: 50px;
}

.error {
  color: red;
}

@media only screen and (max-width: 450px) {
  .loginPage {
    background: #ffffff;
    height: 100vh;
    width: 100%;
  }

  .loginForm {
    width: 100%;
    height: 100%;
  }

  .loginHead {
    width: 100%;
    font-weight: 600;
    font-size: 22px;
  }

  .loginDesc {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
  }

  .loginButton button {
    width: 100%;
    border-radius: 6px;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
